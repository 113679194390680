import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebase/firebase";
import { collection, getDocs, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { Button, Toast, Spinner, Form, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

function UsersFilesRequests() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    message: "",
    variant: "success",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [insertCode, setInsertCode] = useState("");
  const [status, setStatus] = useState("pending");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const allFiles = [];
      for (const userDoc of usersSnapshot.docs) {
        const userId = userDoc.id;
        const userData = userDoc.data();
        const filesSnapshot = await getDocs(collection(db, "users", userId, "files"));
        filesSnapshot.docs.forEach((fileDoc) => {
          allFiles.push({
            userId,
            ...userData,
            ...fileDoc.data(),
            id: fileDoc.id,
          });
        });
      }
      setFiles(allFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
      setToast({
        show: true,
        message: "Error fetching files.",
        variant: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  const handleInsertCodeChange = (e) => {
    setInsertCode(e.target.value);
  };

  const handleUpdate = async () => {
    if (!selectedFile) return;
    try {
      const fileDocRef = doc(
        db,
        "users",
        selectedFile.userId,
        "files",
        selectedFile.id
      );
      await updateDoc(fileDocRef, {
        status,
        insertCode,
      });
      setToast({
        show: true,
        message: "File updated successfully!",
        variant: "success",
      });
      setShowModal(false);
      setInsertCode("");
      setStatus("pending");
      await fetchFiles();
    } catch (error) {
      setToast({
        show: true,
        message: "Error updating file.",
        variant: "danger",
      });
      console.error("Error updating file:", error);
    }
  };

  const handleShowModal = (file) => {
    setSelectedFile(file);
    setStatus(file.status || "pending");
    setInsertCode(file.insertCode || "");
    setShowModal(true);
  };

  const handleDownload = async (file) => {
    try {
      if (!file.fileURL) {
        throw new Error("File URL is undefined");
      }

      const response = await fetch(file.fileURL);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      let filename = file.title || 'download';
      if (!filename.toLowerCase().endsWith('.rbxm')) {
        filename += '.rbxm';
      }
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);

      console.log('Download initiated for:', filename);
    } catch (error) {
      setToast({
        show: true,
        message: "Error initiating file download: " + error.message,
        variant: "danger",
      });
      console.error("Error initiating download:", error);
    }
  };

  const handleDownloadOrNotify = (file) => {
    if (file.status === 'rejected') {
      setToast({
        show: true,
        message: "This file has been rejected and is no longer available.",
        variant: "danger",
      });
    } else {
      handleDownload(file);
    }
  };

  const handleDeletePrompt = (file) => {
    setFileToDelete(file);
    setShowDeleteModal(true);
  };

  const getStoragePathFromUrl = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const matches = decodedUrl.match(/\/o\/(.*?)\?/);
    if (!matches || matches.length < 2) {
      throw new Error('Invalid file URL');
    }
    return matches[1];
  };

  const handleDelete = async () => {
    if (!fileToDelete) return;

    try {
      const storagePath = getStoragePathFromUrl(fileToDelete.fileURL);
      const storageRef = ref(storage, storagePath);
      await deleteObject(storageRef);

      const fileDocRef = doc(db, "users", fileToDelete.userId, "files", fileToDelete.id);
      await deleteDoc(fileDocRef);

      setToast({
        show: true,
        message: "File deleted successfully!",
        variant: "success",
      });

      setShowDeleteModal(false);
      setFileToDelete(null);

      await fetchFiles();
    } catch (error) {
      setToast({
        show: true,
        message: "Error deleting file: " + error.message,
        variant: "danger",
      });
      console.error("Error deleting file:", error);
    }
  };

  const columns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "robloxUserID",
      text: "Roblox UserID",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell) => (
        <span>{cell.charAt(0).toUpperCase() + cell.slice(1)}</span>
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      isDummyField: true,
      formatter: (cell, row) => (
        <>
          <Button variant="outline-primary" onClick={() => handleShowModal(row)}>
            Change
          </Button>{" "}
          <Button variant="outline-success" onClick={() => handleDownloadOrNotify(row)}>
            Download
          </Button>{" "}
          <Button variant="outline-danger" onClick={() => handleDeletePrompt(row)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const paginationOptions = {
    sizePerPage: 10,
    hideSizePerPage: false,
    showTotal: true,
  };

  return (
    <div className="files-panel container mt-4 text-white">
      <h2 className="text-light">All User's Files Requests</h2>
      {loading ? (
        <Spinner animation="border" />
      ) : files.length === 0 ? (
        <p className="text-light">No files found.</p>
      ) : (
        <div className="table-responsive-lg">
          <BootstrapTable
            keyField="id"
            data={files}
            columns={columns}
            bootstrap4
            striped
            bordered
            hover
            pagination={paginationFactory(paginationOptions)}
            filter={filterFactory()}
            headerClasses="table-dark"
            rowClasses="table-dark"
          />
        </div>
      )}

      <Toast
        show={toast.show}
        onClose={() => setToast({ ...toast, show: false })}
        bg={toast.variant}
        className="position-fixed bottom-0 end-0 m-3"
        delay={3000}
        autohide
      >
        <Toast.Body>{toast.message}</Toast.Body>
      </Toast>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>File Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedFile && (
            <Form>
              <Form.Group controlId="formInsertCode">
                <Form.Label>Insert Code</Form.Label>
                <Form.Control
                  type="text"
                  value={insertCode}
                  onChange={handleInsertCodeChange}
                />
              </Form.Group>
              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  <option value="pending">Pending</option>
                  <option value="accepted">Accepted</option>
                  <option value="rejected">Rejected</option>
                </Form.Control>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this file? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UsersFilesRequests;

import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {
  Card,
  Spinner,
  Row,
  Col,
  Container,
  Button,
  Modal,
  Form,
  Table,
} from "react-bootstrap";
import { FaFilePdf, FaFileImage, FaFileWord, FaFileAlt } from "react-icons/fa";

const UserFiles = ({ currentUser }) => {
  const [files, setFiles] = useState([]);
  const [robloxUserID, setRobloxUserID] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userIds, setUserIds] = useState("");
  const uid = currentUser && currentUser ? currentUser.uid : null;

  useEffect(() => {
    if (!uid) {
      setError("User is not authenticated.");
      return;
    }

    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, "users", uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRobloxUserID(userData.robloxUserID || "No Roblox User ID found");
        } else {
          setError("User data not found.");
        }

        const filesRef = collection(db, "users", uid, "files");
        const q = query(filesRef);
        const querySnapshot = await getDocs(q);

        const filesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setFiles(filesData);
      } catch (err) {
        setError("Failed to fetch data.");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uid]);

  const getFileIcon = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();

    switch (fileExtension) {
      case "pdf":
        return <FaFilePdf size={64} color="red" />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <FaFileImage size={64} color="blue" />;
      case "doc":
      case "docx":
        return <FaFileWord size={64} color="blue" />;
      default:
        return <FaFileAlt size={64} color="gray" />;
    }
  };

  const handleCardClick = async (file) => {
    if (file.status === "accepted") {
      await fetchFileDetails(file.id); 
      setShowModal(true);
    }
  };

  const fetchFileDetails = async (fileId) => {
    try {
      const fileDocRef = doc(db, "users", uid, "files", fileId);
      const fileDoc = await getDoc(fileDocRef);

      if (fileDoc.exists()) {
        setSelectedFile({ id: fileId, ...fileDoc.data() });
      } else {
        console.error("File not found");
      }
    } catch (error) {
      console.error("Error fetching file details:", error);
    }
  };

  const handleAddUserIds = async () => {
    if (!selectedFile || !userIds) return;

    const newIds = userIds.split(",").map((id) => id.trim());
    const updatedUserIds = selectedFile.userIds
      ? [...selectedFile.userIds, ...newIds]
      : newIds;

    const fileDocRef = doc(db, "users", uid, "files", selectedFile.id);

    try {
      await updateDoc(fileDocRef, {
        userIds: updatedUserIds,
      });

      setSelectedFile((prevFile) => ({
        ...prevFile,
        userIds: updatedUserIds,
      }));

      setUserIds("");
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Failed to add user IDs.");
    }
  };

  const handleModalClose = () => {
    setSelectedFile(null); 
    setShowModal(false);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" className="text-white" />
      </div>
    );
  }
  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <h3 className="text-center text-white">
        Your Roblox User ID: {robloxUserID}
      </h3>

      <Container className="ms-auto w-100 px-md-5 px-2 py-4">
        <Row className="d-flex align-items-start">
          {files.length === 0 ? (
            <p className="text-white">No files found.</p>
          ) : (
            files.map((file) => (
              <Col key={file.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <Card
                  className="h-100"
                  onClick={() => handleCardClick(file)}
                  style={{
                    cursor: file.status === "accepted" ? "pointer" : "default",
                  }}
                >
                  <div className="d-flex justify-content-center mt-3">
                    {getFileIcon(file.fileURL)}{" "}
                  </div>
                  <Card.Body className="d-flex flex-column">
                    <Card.Title>{file.title}</Card.Title>
                    <Card.Text>{file.description}</Card.Text>
                    <Card.Text>
                      <strong>Status:</strong> {file.status}
                    </Card.Text>
                    {file.insertCode && (
                      <Card.Text>
                        <strong>Insert Code:</strong> {file.insertCode}
                      </Card.Text>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Manage User IDs for Insert Code: {selectedFile?.insertCode}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedFile?.userIds?.length > 0 ? (
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th>User IDs</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedFile.userIds.map((id, index) => (
                  <tr key={index}>
                    <td>{id}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          const updatedUserIds = selectedFile.userIds.filter(
                            (userId) => userId !== id
                          );
                          updateDoc(
                            doc(db, "users", uid, "files", selectedFile.id),
                            {
                              userIds: updatedUserIds,
                            }
                          );
                          setSelectedFile({
                            ...selectedFile,
                            userIds: updatedUserIds,
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No user IDs found for this insert code.</p>
          )}

          <Form.Group>
            <Form.Label>Add User IDs (comma separated)</Form.Label>
            <Form.Control
              type="text"
              value={userIds}
              onChange={(e) => setUserIds(e.target.value)}
              placeholder="e.g., user1, user2, user3"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddUserIds}>
            Add User IDs
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserFiles;

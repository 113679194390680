import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";

const CreatorHub = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/user");
      }
    });
    return () => unsubscribe();
  }, [navigate]);
  return (
    <div className="background overflow-hidden">

      <div className="d-flex flex-column justify-content-center align-items-center h-100 text-white text-center">
        <h1 className="custom-font-h1">
          Submit your own <br /> custom insert
        </h1>
        <p className="sm-fs-5 pb-6">
          Everything you need to start provided here!
          <br /> Start the submission of your own custom insert now.
        </p>
        <button
          type="button"
          className="start-creating"
          onClick={() => navigate("/login")}
        >
          Start Submission
        </button>
      </div>
    </div>
  );
};

export default CreatorHub;

import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase";
import { doc, getDocs, updateDoc, collection, query, where } from "firebase/firestore";
import {  Button, Toast, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

function AdminPanel({ currentUser }) {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [loadingUserId, setLoadingUserId] = useState(null); 
  const [toast, setToast] = useState({ show: false, message: "", variant: "success" }); 

  useEffect(() => {
    const fetchPendingUsers = async () => {
      try {
        const q = query(collection(db, "users"), where("status", "==", "pending"));
        const querySnapshot = await getDocs(q);
        const usersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPendingUsers(usersList);
      } catch (error) {
        console.error("Error fetching pending users:", error);
      } finally {
        setLoading(false); 
      }
    };
    fetchPendingUsers();
  }, []);

  // Approve user
  const handleApprove = async (userId) => {
    setLoadingUserId(userId); 
    try {
      await updateDoc(doc(db, "users", userId), { status: "accepted" });
      setPendingUsers((prev) => prev.filter((user) => user.id !== userId));
      setToast({ show: true, message: "User approved successfully!", variant: "success" });
    } catch (error) {
      setToast({ show: true, message: "Error approving user.", variant: "danger" });
      console.error("Error approving user:", error);
    } finally {
      setLoadingUserId(null); 
    }
  };

  // Reject user
  const handleReject = async (userId) => {
    setLoadingUserId(userId); 
    try {
      await updateDoc(doc(db, "users", userId), { status: "rejected" });
      setPendingUsers((prev) => prev.filter((user) => user.id !== userId));
      setToast({ show: true, message: "User rejected successfully!", variant: "success" });
    } catch (error) {
      setToast({ show: true, message: "Error rejecting user.", variant: "danger" });
      console.error("Error rejecting user:", error);
    } finally {
      setLoadingUserId(null); 
    }
  };

  const columns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "robloxUserID",
      text: "Roblox UserID",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      isDummyField: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              variant="outline-success"
              className="me-2"
              onClick={() => handleApprove(row.id)}
              disabled={loadingUserId === row.id}
            >
              {loadingUserId === row.id ? <Spinner animation="border" /> : "Accept"}
            </Button>
            <Button
              variant="outline-danger"
              onClick={() => handleReject(row.id)}
              disabled={loadingUserId === row.id}
            >
              {loadingUserId === row.id ? <Spinner animation="border"  /> : "Reject"}
            </Button>
          </>
        );
      },
    },
  ];

  const paginationOptions = {
    sizePerPage: 5,
    hideSizePerPage: true,
    showTotal: true,
  };

  return (
    <div className="admin-panel container mt-4 text-white">
      <h2 className="text-light">Pending User Approvals</h2>

      {loading ? ( 
        <div className="d-flex  vh-100">
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : pendingUsers.length === 0 ? (
        <p className="text-light">No pending users.</p>
      ) : (
        <BootstrapTable
          keyField="id"
          data={pendingUsers}
          columns={columns}
          bootstrap4
          striped
          bordered
          hover
          pagination={paginationFactory(paginationOptions)}
          filter={filterFactory()}
          headerClasses="table-dark"
          rowClasses="table-dark"
        />
      )}

      {/* Bootstrap Toast for displaying error/success messages */}
      <Toast
        show={toast.show}
        onClose={() => setToast({ ...toast, show: false })}
        bg={toast.variant}
        className="position-fixed bottom-0 end-0 m-3"
        delay={3000}
        autohide
      >
        <Toast.Body>{toast.message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default AdminPanel;
